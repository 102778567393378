import React, { ReactNode } from "react";
import { Parallax } from "react-parallax";

type ParallaxBannerProps = {
  readonly id?: string;
  readonly title?: string;
  readonly image: string;
  readonly dontConstrainWidth?: boolean;
  readonly left?: ReactNode;
  readonly children: ReactNode | ReadonlyArray<ReactNode>;
};

function ParallaxBanner({
  id,
  title,
  image,
  dontConstrainWidth,
  left,
  children,
}: ParallaxBannerProps) {
  return (
    <Parallax
      className="parallax-banner"
      bgImage={image}
      bgImageAlt={title}
      blur={2}
      strength={300}
    >
      <div
        id={id}
        className="Index-page-content  sqs-alternate-block-style-container"
      >
        {left && <div>{left}</div>}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
          }}
        >
          {title && (
            <h2
              style={{
                textAlign: "center",
                whiteSpace: "pre-wrap",
                filter: "drop-shadow(0 0 0.75rem black)",
              }}
            >
              {title}
            </h2>
          )}
          <div
            style={{
              maxWidth: dontConstrainWidth ? undefined : 750,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default ParallaxBanner;

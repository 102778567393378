import React, { ReactNode } from "react";
import uiIconsImage from "~/images/ui-icons.svg";
import UniversalLinkButton, {
  UniversalLinkButtonProps,
} from "~/components/UniversalLinkButton";

type SimpleVerticalTextAndButtonsProps = {
  readonly title: ReactNode;
  readonly text: string;
  readonly buttons: readonly Pick<
    UniversalLinkButtonProps,
    "to" | "children"
  >[];
};

function SimpleVerticalTextAndButtons({
  title,
  text,
  buttons,
}: SimpleVerticalTextAndButtonsProps) {
  return (
    <section className="Index-page">
      <div
        className="Index-page-scroll-indicator"
        data-controller="ScrollIndicator"
        data-controllers-bound="ScrollIndicator"
      >
        <div className="Index-page-scroll-indicator-text">Scroll</div>
        <svg
          className="Index-page-scroll-indicator-arrow Icon Icon--caretLarge--down"
          viewBox="0 0 48 23"
        >
          <use href={`${uiIconsImage}#caret-down-large-icon`} />
        </svg>
        <div className="Index-page-scroll-indicator-line" />
      </div>
      <div className="Index-page-content ">
        <div
          className="sqs-layout sqs-grid-12 columns-12"
          data-type="page"
          id="page-5f28819e4026637dd633c55c"
        >
          <div className="row sqs-row">
            <div className="col sqs-col-12">
              <div
                className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                data-block-type="21"
                id="block-6d7b47c69364cfae979a"
              >
                <div className="sqs-block-content">&nbsp;</div>
              </div>
            </div>
          </div>
          <div className="row sqs-row">
            <div className="col sqs-col-1 span-1">
              <div
                className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                data-block-type="21"
                id="block-6de4ed4f7a83a993f9cf"
              >
                <div className="sqs-block-content">&nbsp;</div>
              </div>
            </div>
            <div className="col sqs-col-9">
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-ace574121596dd5bcaf0"
              >
                <div className="sqs-block-content">
                  <h2 style={{ whiteSpace: "pre-wrap" }}>{title}</h2>
                  <p style={{ whiteSpace: "pre-wrap" }}>{text}</p>
                </div>
              </div>
            </div>
            <div className="col sqs-col-2 span-2">
              <div
                className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                data-block-type="21"
                id="block-cfbe9c227c6df0aa295e"
              >
                <div className="sqs-block-content">&nbsp;</div>
              </div>
            </div>
          </div>
          <div className="row sqs-row">
            <div className="col sqs-col-12">
              <div
                className="sqs-block-button-container sqs-block-button-container--center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                {buttons.map((b) => (
                  <UniversalLinkButton
                    key={b.to}
                    colour="red"
                    size="large"
                    variant="solid"
                    to={b.to}
                  >
                    {b.children}
                  </UniversalLinkButton>
                ))}
              </div>
              <div
                className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                data-block-type="21"
                id="block-f58a25fd721bab0eb100"
              >
                <div className="sqs-block-content">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SimpleVerticalTextAndButtons;

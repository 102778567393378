import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "~/components/Seo";
import ParallaxBanner from "~/components/ParallaxBanner";
import gatekeeperScreenshotImage from "~/images/Gatekeeper_cropped.jpg";
import DownloadSection from "~/components/DownloadSection";
import SimpleVerticalTextAndButtons from "~/components/SimpleVerticalTextAndButtons";
// import gameOverviewDocument from "~/images/home-game-overview.pdf";

function HomePage() {
  return (
    <div
      className="Site-inner Site-inner--index"
      id="yui_3_17_2_1_1651536660296_82"
    >
      <header className="Header Header--bottom">
        <div
          className="Header-inner Header-inner--bottom"
          data-nc-group="bottom"
        >
          <div data-nc-container="bottom-left" />
          <div data-nc-container="bottom-center" />
          <div data-nc-container="bottom-right" />
        </div>
      </header>
      <div className="Content-outer" id="yui_3_17_2_1_1651536660296_81">
        <main
          className="Index"
          data-collection-id="5efe203b529d2c2a9d9f7d90"
          data-controller="IndexFirstSectionHeight, IndexNavigation"
          id="yui_3_17_2_1_1651536660296_80"
          data-controllers-bound="IndexFirstSectionHeight, IndexNavigation"
        >
          <SimpleVerticalTextAndButtons
            title={
              <>
                Don&apos;t let Algebra, aka the <em>gatekeeper</em>, keep your
                students from their goals!
              </>
            }
            text="Help your middle schoolers gain a deeper understanding
            of algebra concepts, combat pandemic learning loss,
            and simply have fun learning math through our 3D
            adventure game."
            buttons={[
              {
                to: "/get-started/homes-learn-more/",
                children: "For Families",
              },
              {
                to: "/get-started/schools-learn-more/",
                children: "For Schools and Districts",
              },
            ]}
          />
          <ParallaxBanner
            id="bannerwithvideo"
            image={gatekeeperScreenshotImage}
            title="ProblemScape for Introductory Algebra"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 16,
              }}
            >
              <StaticImage
                src="../images/ps-logo-subtitle.png"
                alt="ProblemScape Logo"
                width={200}
                placeholder="none"
              />
              <p style={{ whiteSpace: "pre-wrap" }}>
                ProblemScape is a problem-based learning course for introductory
                algebra. The adventure game aligns with Common Core as well as
                most other state standards.
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <div
                className="intrinsic"
                style={{ maxWidth: "100%", position: "relative" }}
              >
                <div
                  className="embed-block-wrapper "
                  style={{ paddingBottom: "56.20609%", position: "relative" }}
                >
                  <iframe
                    width="1280"
                    height="720"
                    src="https://www.youtube.com/embed/9upkfd1y-2A"
                    title="ProblemScape Trailer"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      height: "100%",
                      left: 0,
                      position: "absolute",
                      top: 0,
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </ParallaxBanner>
          <section
            id="pshelps"
            className="Index-page"
            data-collection-id="62dea26019970a3db2e86328"
            data-edit-main-image="Background"
          >
            <div className="Index-page-content ">
              <div
                className="sqs-layout sqs-grid-12 columns-12"
                data-type="page"
                id="page-62dea26019970a3db2e86328"
              >
                <div className="row sqs-row">
                  <div className="col sqs-col-12">
                    <div
                      className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                      data-block-type="21"
                      id="block-38192d5cfc9b4ae6f01d"
                    >
                      <div className="sqs-block-content">&nbsp;</div>
                    </div>
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-f82b5155f557c81479cc"
                    >
                      <div className="sqs-block-content">
                        <h2
                          style={{
                            textAlign: "center",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          ProblemScape has everything students need to master
                          Algebra
                        </h2>
                      </div>
                    </div>
                    <div
                      className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                      data-block-type="21"
                      id="block-07c1580175574e63e7a4"
                    >
                      <div className="sqs-block-content">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="row sqs-row">
                  <div className="col sqs-col-3 span-3">
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-3047450cf593bac9ec42"
                    >
                      <div className="sqs-block-content">
                        <h3>
                          Adventure to make math relevant and reduce math
                          anxiety
                        </h3>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          In ProblemScape, the fun adventure narrative puts a
                          spotlight on applications to make algebra meaningful
                          and help overcome math anxiety.
                          <br />
                          <Link to="/research/69u51bbtnacr79o5ttd9duwyt7lcpy/">
                            Read more…
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col sqs-col-3 span-3">
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-035f667fe9dcce8e590a"
                    >
                      <div className="sqs-block-content">
                        <h3>
                          Multimodal research-backed content to learn concepts{" "}
                        </h3>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          Videos and animations in ProblemScape are grounded in
                          mathematics education research and explain concepts
                          while addressing common errors and misconceptions.{" "}
                          <br />
                          <Link to="/research/all-equals-are-not-equal/">
                            Read more…
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col sqs-col-3 span-3">
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-154609400e45c00b28f9"
                    >
                      <div className="sqs-block-content">
                        <h3>
                          Practice to develop self-efficacy and metacognition
                        </h3>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          Instead of having students solve problem after problem
                          to progress through an adaptive sequence, practice in
                          ProblemScape gives students autonomy and control, both
                          of which are shown to increase motivation and
                          self-efficacy. <br />
                          <Link to="/research/practice-can-lead-to-proficiency-but-only-if/">
                            Read more…
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col sqs-col-3 span-3">
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-9f45a02f7a77750d038a"
                    >
                      <div className="sqs-block-content">
                        <h3>
                          Scaffolded review to prepare for tests and assessments
                        </h3>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          In education, the scaffold metaphor is used to
                          indicate the support given to students for enabling
                          learning and retention. ProblemScape provides students
                          with just the right scaffold so that they reap the
                          benefits of spaced reviews and tests.
                          <br />
                          <Link to="/research/the-good-is-in-the-testing-not-the-results/">
                            Read more…
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row sqs-row">
                  <div className="col sqs-col-12">
                    <div
                      className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                      data-aspect-ratio="0.12254901960784313"
                      data-block-type="21"
                      id="block-0c3177b4cca31fc2cf90"
                    >
                      <div
                        className="sqs-block-content sqs-intrinsic"
                        id="yui_3_17_2_1_1671156037374_322"
                        style={{ paddingBottom: "0.122549%" }}
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <DownloadSection />
          <nav className="Index-nav">
            <div className="Index-nav-inner">
              <a href="#introduction-2" className="Index-nav-item active">
                <div className="Index-nav-indicator" />
                <div className="Index-nav-text">
                  <span>Introduction (Copy)</span>
                </div>
              </a>
              <a href="#bannerwithvideo" className="Index-nav-item">
                <div className="Index-nav-indicator" />
                <div className="Index-nav-text">
                  <span>Banner (Copy)</span>
                </div>
              </a>
              <a href="#download-links" className="Index-nav-item">
                <div className="Index-nav-indicator" />
                <div className="Index-nav-text">
                  <span>App downloads</span>
                </div>
              </a>
              <a href="#pshelps" className="Index-nav-item">
                <div className="Index-nav-indicator" />
                <div className="Index-nav-text">
                  <span>ProblemScape Helps</span>
                </div>
              </a>
              <a href="#new-page-1" className="Index-nav-item">
                <div className="Index-nav-indicator" />
                <div className="Index-nav-text">
                  <span>New Page</span>
                </div>
              </a>
            </div>
          </nav>
        </main>
      </div>
    </div>
  );
}

export function Head() {
  return <Seo title="Home" />;
}

export default HomePage;
